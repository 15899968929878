<template>
  <div>
    <a-spin :spinning="loading">
      <a-form ref="form" name="form" :model="modelRef" :labelCol="{ span: 4 }" :wrapperCol="{span: 16}" @finish="onSubmit">

        <h4 style="padding-left: 20px;border-left: solid 5px #169bd5;margin-bottom: 20px;font-size: 20px;">一卡通发放</h4>

        <a-form-item label="发放类型" name="type" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-radio-group v-model:value="modelRef.type" button-style="solid" @change="onChange">
            <a-radio-button :value="1">全部</a-radio-button>
            <a-radio-button :value="2">指定会员等级</a-radio-button>
            <a-radio-button :value="3">指定用户</a-radio-button>
            <a-radio-button :value="4">指定手机号</a-radio-button>
          </a-radio-group>
        </a-form-item>

        <a-form-item v-if="modelRef.type === 2" label="会员等级" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-form-item-rest>
            <selectMember v-model:value="memberIdList" :selectItems="memberList" @change="(dataList) => {memberList = dataList}"></selectMember>
          </a-form-item-rest>
          <div style="margin-top: 10px; width: 700px;" v-if="memberList.length">
            <a-table :pagination="false" :columns="memberColumns" :dataSource="memberList" rowKey="id"
                     :scroll="{ x: 450 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onMemberDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item v-if="modelRef.type === 3" label="用户" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-form-item-rest>
            <selectBaseUser v-model:value="userIdList" :selectItems="userList" @change="(dataList) => {userList = dataList}"></selectBaseUser>
          </a-form-item-rest>
          <div style="margin-top: 10px; width: 700px;" v-if="userList.length">
            <a-table :pagination="false" :columns="userColumns" :dataSource="userList" rowKey="id"
                     :scroll="{ x: 450 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'cardType'">
                  <div v-if="record.cardType === 1">普通卡</div>
                  <div v-if="record.cardType === 2">主题卡</div>
                  <div v-if="record.cardType === 3">礼品卡</div>
                </template>
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onUserDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

        <a-form-item v-if="modelRef.type === 4" label="用户手机号" name="phone" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input style="width: 200px;" v-model:value="modelRef.phone" placeholder="请输入用户手机号"></a-input>
        </a-form-item>

        <a-form-item label="余额" name="parPrice" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input style="width: 200px;" v-model:value="modelRef.parPrice" placeholder="请输入余额"></a-input>
        </a-form-item>

        <a-form-item label="一卡通" :rules="[{required: true, message: '必填项不允许为空'}]" extra="只能选择一个一卡通">
          <a-form-item-rest>
            <selectOneCard v-model:value="idList" :selectItems="list" type="radio" @change="(dataList) => {list = dataList}"></selectOneCard>
          </a-form-item-rest>
          <div style="margin-top: 10px; width: 700px;" v-if="list.length">
            <a-table :pagination="false" :columns="columns" :dataSource="list" rowKey="id"
                     :scroll="{ x: 450 }">
              <template #bodyCell="{ column, record, index }">
                <template v-if="column.key === 'cardType'">
                  <div v-if="record.cardType === 1">普通卡</div>
                  <div v-if="record.cardType === 2">主题卡</div>
                  <div v-if="record.cardType === 3">礼品卡</div>
                </template>
                <template v-if="column.key === 'action'">
                  <a-button type="link" @click="onDelete(record, index)">删除</a-button>
                </template>
              </template>
            </a-table>
          </div>
        </a-form-item>

<!--        <a-form-item label="是否启用" name="isDisabled">-->
<!--          <a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />-->
<!--        </a-form-item>-->

        <a-row>
          <a-col :offset="6" style="margin-top: 40px;">
            <a-button type="primary" html-type="submit">发放</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import selectOneCard from "@/components/select/selectOneCard";
import selectBaseUser from "@/components/select/selectBaseUser";
import selectMember from "@/components/select/selectMember";
import {grantUnionCard} from "../../../../service/modules/coupon";
import { deleteGoods } from "@/service/modules/goods";
export default {
  components: {
    selectOneCard,
    selectBaseUser,
    selectMember,
  },
  data() {
    return {
      loading: false,
      modelRef: {
        type: 1,
        isDisabled: true,
      },
      memberColumns: [{
        title: '会员等级',
        dataIndex: 'level',
      }, {
        title: '所需积分',
        dataIndex: 'needGrowth',
      }, {
        title: '操作',
        key: 'action',
      }],
      memberIdList: [],
      memberList: [],
      userColumns: [{
        title: '用户名称',
        dataIndex: 'nickname'
      }, {
        title: '用户电话',
        dataIndex: 'phone'
      }, {
        title: '操作',
        key: 'action',
      }],
      userIdList: [],
      userList: [],
      columns: [{
        title: '一卡通名称',
        dataIndex: 'title'
      }, {
        title: '一卡通类型',
        key: 'cardType'
      }, {
        title: '操作',
        key: 'action',
      }],
      idList: [],
      list: [],
    }
  },
  created() {
  },
  methods: {
    onDelete(item, index) {
      let i = this.idList.indexOf(item.id);
      this.idList.splice(i, 1);
      this.list.splice(index, 1);
    },
    onUserDelete(item, index) {
      let i = this.userIdList.indexOf(item.id);
      this.userIdList.splice(i, 1);
      this.userList.splice(index, 1);
    },
    onMemberDelete(item, index) {
      let i = this.memberIdList.indexOf(item.id);
      this.memberIdList.splice(i, 1);
      this.memberList.splice(index, 1);
    },
    onChange() {
      this.memberList = [];
      this.memberIdList = [];
      this.userList = [];
      this.userIdList = [];
      delete this.modelRef.phone;
    },
    async onSubmit() {
      if(this.modelRef.type === 2 && this.memberIdList.length === 0) {
        this.$message.error('请选择会员等级');
        return;
      }
      if(this.modelRef.type === 3 && this.userIdList.length === 0) {
        this.$message.error('请选择用户');
        return;
      }
      if(this.idList.length === 0) {
        this.$message.error('请选择一卡通');
        return;
      }
      if(this.idList.length > 1) {
        this.$message.error('只能选择一个一卡通');
        return;
      }
      this.$confirm({
        title: '提示',
        content: '确定发放吗?',
        onOk: async ()=> {
          this.loading = true;
          try {
            let ret = await grantUnionCard({
              type: this.modelRef.type,
              businessId: this.memberIdList.join(','),
              userIds: this.userIdList.join(','),
              phone: this.modelRef.phone,
              parPrice: this.modelRef.parPrice,
              unionCardId: this.idList.join()
            })
            this.loading = false;
            if(ret.code === 200) {
              this.$message.success('操作成功');
              this.modelRef = {
                type: 1,
                isDisabled: false
              }
              this.memberList = [];
              this.memberIdList = [];
              this.userList = [];
              this.userIdList = [];
              this.idList = [];
              this.list = [];
            }
          } catch(e) {
            this.loading = false;
          }
        }
      })
    },
  },
}
</script>

<style scoped>
</style>
